import Image from 'next/image'
import {Col, Container, Row} from 'react-bootstrap'
import {LogoListParagraph} from '../../types/paragraphs'
import GenericLink from '../shared/Link'
import 'react-multi-carousel/lib/styles.css'
import Carousel from 'react-multi-carousel'
import {AdditionalContent} from '../../types/api-types'
import ConditionalSection from '../shared/ConditionalWrapper'

type Props = {
  paragraph: LogoListParagraph
  additionalContent: AdditionalContent
}

const LogoList = ({paragraph, additionalContent}: Props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 2,
      slidesToSlide: 2,
    },
  }
  return (
    <Container fluid={true} className="section-box py-3 my-5">
      <Row className="px-lg-4 justify-content-center">
        <Col lg={'8'} className="p-lg-5 py-5 logo-list justify-content-center">
          {paragraph.field_title ? (
            <p className="text-center h3 font-weight-normal mb-4">
              {paragraph.field_title.replace(
                '$location',
                additionalContent.initialLocationQuery?.value ?? '',
              )}
            </p>
          ) : null}
          {paragraph.field_short_description ? (
            <p className="text-center text-secondary">
              {paragraph.field_short_description.replace(
                '$location',
                additionalContent.initialLocationQuery?.value ?? '',
              )}
            </p>
          ) : null}
          <Carousel
            responsive={responsive}
            infinite={true}
            arrows={false}
            autoPlay
            autoPlaySpeed={5000}
            pauseOnHover={false}
            swipeable={false}
            draggable={false}
            itemClass="logo-list__logos__logo position-relative"
            className="my-5"
          >
            {paragraph.field_logo
              .slice(0, Math.ceil(paragraph.field_logo.length / 2))
              .map(logo =>
                logo.field_media ? (
                  <ConditionalSection
                    key={logo.id}
                    condition={!!logo.field_button?.uri}
                    wrapper={(childrenPassed: any) => (
                      <GenericLink
                        key={logo.id}
                        extraClass="logo-list__logos__logo position-relative d-block"
                        link={logo.field_button}
                      >
                        {childrenPassed}
                      </GenericLink>
                    )}
                  >
                    <Image
                      src={`${logo.field_media.field_media_image.uri?.url}`}
                      className="object-fit-contain px-5"
                      fill
                      alt={
                        logo.field_media.field_media_image.resourceIdObjMeta
                          ?.alt ?? ''
                      }
                      sizes="(max-width: 767px) 40vw, 15vw"
                    ></Image>
                  </ConditionalSection>
                ) : null,
              )}
          </Carousel>
          <Carousel
            responsive={responsive}
            infinite={true}
            arrows={false}
            autoPlay
            autoPlaySpeed={5000}
            pauseOnHover={false}
            swipeable={false}
            draggable={false}
            itemClass="logo-list__logos__logo position-relative"
            className="my-5"
          >
            {paragraph.field_logo
              .slice(Math.ceil(paragraph.field_logo.length / 2))
              .map(logo =>
                logo.field_media ? (
                  <ConditionalSection
                    key={logo.id}
                    condition={!!logo.field_button?.uri}
                    wrapper={(childrenPassed: any) => (
                      <GenericLink
                        key={logo.id}
                        extraClass="logo-list__logos__logo position-relative d-block"
                        link={logo.field_button}
                      >
                        {childrenPassed}
                      </GenericLink>
                    )}
                  >
                    <Image
                      src={`${logo.field_media.field_media_image.uri?.url}`}
                      className="px-5 object-fit-contain"
                      fill
                      alt={
                        logo.field_media.field_media_image.resourceIdObjMeta
                          ?.alt ?? ''
                      }
                      sizes="(max-width: 767px) 40vw, 15vw"
                    ></Image>
                  </ConditionalSection>
                ) : null,
              )}
          </Carousel>
          {paragraph.field_button ? (
            <div className="d-flex justify-content-center">
              <GenericLink
                link={paragraph.field_button}
                extraClass="btn btn-secondary text-center bg-white"
              >
                {paragraph.field_button.title.replace(
                  '$location',
                  additionalContent.initialLocationQuery?.value ?? '',
                )}
              </GenericLink>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}

export default LogoList
